import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App'

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
      <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>
        ⚠︎ xOS.NETWORK: DISABLED<br />
        ⎋ UPLOADING TO DEATHCORD<br />
       <b><a href="https://deathcord.com/"style={{ fontSize: '13px', color: 'red' }}>► ENTER DEATHCORD</a></b>
      
       </div>
      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}></div>
    </div>
  )
}

createRoot(document.getElementById('root')).render(
  <>
    <App />
    <Overlay />
  </>
)
